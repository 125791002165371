import axios from 'axios';

export async function getImageUrl(id) {
  
    // Call the API Gateway to trigger the Lambda function
    const imageUrl = await axios.post('https://5celnyj5u0.execute-api.us-east-1.amazonaws.com/dev/getUrl', {
      id,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return imageUrl
}

export async function insertImageUrl(imageUrl) {
  
    // Call the API Gateway to trigger the Lambda function
    const response = await axios.post('https://5celnyj5u0.execute-api.us-east-1.amazonaws.com/dev/insertUrl', {
      imageUrl,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log("RESPONSE: ", response.data.id)

    const id = response.data.id; // Extract the id from the response

    return id;
  }