import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Table, Card, Pagination } from 'react-bootstrap';
import '../../styles/accountpage.css';
import { getDesigns } from '../../apis/aws_s3';
import { retrieveOrders } from '../../apis/retrieve_orders';
import { withAuthenticator } from '@aws-amplify/ui-react';

const itemsPerPage = 10;

const Account = () => {
  const [user, setUser] = useState(null);
  const history = useNavigate();
  const [view, setView] = useState('main');
  const [designs, setDesigns] = useState([]);
  const [designsPage, setDesignsPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [ordersPage, setOrdersPage] = useState(1);

  useEffect(() => {
    getUserInfo();
    getDesigns().then((designs) => setDesigns(designs));
  }, []);
  
  useEffect(() => {
    if (user) {
      retrieveOrders(user.email).then((response) => setOrders(response.orders));
    }
  }, [user]);

  const getPaginatedItems = (items, activePage) => {
    if (!Array.isArray(items)) {
      console.error('Error: Items data is not an array');
      return [];
    }
    
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };
  

  const handleDesignsPageChange = (pageNumber) => {
    setDesignsPage(pageNumber);
  };

  const handleOrdersPageChange = (pageNumber) => {
    setOrdersPage(pageNumber);
  };

  const renderPagination = (totalItems, currentPage, onPageChange) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    let items = [];
  
    for (let i = 1; i <= pageCount; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>,
      );
    }
  
    return (
      <Pagination className="custom-pagination">{items}</Pagination>
    );
  };

  const getUserInfo = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const { email } = currentUser.attributes;
      console.log(email)
      setUser({ ...currentUser, email: email });
    } catch (error) {
      console.error('Error fetching user info:', error);
      history.push('/signin');
    }
  };
  

  if (!user) {
    return <div>Loading...</div>;
  }

  const paginatedDesigns = getPaginatedItems(designs, designsPage);
  const paginatedOrders = getPaginatedItems(orders, ordersPage);

  return (
    <Container fluid className="account-page mt-5 pt-3">
      <Container fluid className="mt-5">
        <h1 style={{fontSize: 20, fontWeight: 'bold'}}>My Designs</h1>
      </Container>
      <>
  <Container className="mt-0">
    <Row className="justify-content-center">
      {paginatedDesigns.length > 0 ? (
        paginatedDesigns.map((design) => (
          <Col xs={6} sm={6} md={4} lg={3} className="pt-1" key={design.key}>
            <Card style={{ borderRadius: 0, border: 0 }}>
              <Card.Img style={{ borderRadius: 0 }} variant="top" src={design.url} />
              <Card.Body>
                <Card.Text style={{ fontSize: 12 }}>{design.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))
      ) : (
        <p>No designs found</p>
      )}
    </Row>
    {renderPagination(designs.length, designsPage, handleDesignsPageChange)}
  </Container>
</>

      <>
      <Container className="mt-0">
        <h1 style={{fontSize: 20, fontWeight: 'bold'}}>Orders</h1>
        { orders && orders.length > 0 ? (
          <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Status</th>
              <th>Tracking Number</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.orderNumber}</td>
                <td>{order.orderDate}</td>
                <td>{order.status}</td>
                <td>{order.tracking_number}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {renderPagination(orders.length, ordersPage, handleOrdersPageChange)}
        </>
        ) : (
          <p>No orders found</p>
        )}
        </Container>
      </>
</Container>
  )}
export default withAuthenticator(Account)