import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../../styles/featuredproducts.css';
import { useNavigate } from 'react-router-dom';


const products = [
  { id: 1, name: 'T-Shirt', image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578372/youdesignai/websiteAssets/tshirt.png' },
  { id: 2, name: 'Tank Top', image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578374/youdesignai/websiteAssets/tanktop.png' },
  { id: 3, name: 'Hoodie', image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578371/youdesignai/websiteAssets/hoodie.png' }
];

const FeaturedProducts = () => {
  const navigate = useNavigate();

  const handleProductSelect = (product) => {
    navigate(`/design-shirt?product=${product.id}`);
  };

  return (
    <>
      <h2 id='boldFont' style={{ fontSize: 20}} >Featured Products</h2>
      <Row className="mt-3">
        {products.map(product => (
          <Col key={product.id} xs={6} sm={6} md={4} lg={4}>
            <Card className="product-card mb-4" style={{ borderRadius: 0, border: 0 }} onClick={() => handleProductSelect(product)}>
              <Card.Img style={{ borderRadius: 0 }} src={`${product.image}`} />
              <Card.Body>
                <Card.Title style={{ fontSize: 14, height: 0 }}>{product.name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>

  );
};

export default FeaturedProducts;
