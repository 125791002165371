import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import '../../styles/designshirt.css';
import Prompt from './Prompt/Prompt';
import GeneratedDesigns from './GeneratedDesigns/GeneratedDesigns';
import SelectedDesign from './SelectedDesign/SelectedDesign';
import { generateImage } from '../../apis/image_generation';
import {useCart} from '../../components/ShoppingCart/CartContext'
import { useNavigate, useLocation } from 'react-router-dom';
import { uploadFileFromUrl } from '../../apis/aws_s3';

const DesignShirt = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);      
    const [showGeneratedDesigns, setShowGeneratedDesigns] = useState(false);
    const [generatedDesigns, setGeneratedDesigns] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { addToCart } = useCart();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const productId = searchParams.get('product');
    const [lastDescription, setLastDescription] = useState(null);


    const options = [
      {
        id: 1,
        name: 'T-Shirt',
        imageUrl:
          'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578372/youdesignai/websiteAssets/tshirt.png',
      },
      {
        id: 2,
        name: 'Tank Top',
        imageUrl:
          'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578374/youdesignai/websiteAssets/tanktop.png',
      },
      {
        id: 3,
        name: 'Sweatshirt',
        imageUrl:
          'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578371/youdesignai/websiteAssets/sweatshirt.png',
      },
      {
        id: 4,
        name: 'Hoodie',
        imageUrl:
          'https://res.cloudinary.com/dhygjkoey/image/upload/v1681578371/youdesignai/websiteAssets/hoodie.png',
      },
    ];

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
      if (productId) {
        const selectedOption = options.find(option => option.id === parseInt(productId));
        setSelectedOption(selectedOption);
        setShowPrompt(true);
      }
    }, [productId]);
  
    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setShowPrompt(true);
    };
  
    const handleBackToOptionsClick = () => {
      setSelectedOption(null);
      setShowPrompt(false);
      setShowGeneratedDesigns(false);
      setGeneratedDesigns([]);
    };
  
    const handleBackToDescriptionsClick = () => {
      setError(null);
      setShowPrompt(true);
      setShowGeneratedDesigns(false);
      setGeneratedDesigns([]);
    };
  
    const handleDesignDeselect = () => {
      setShowPrompt(false);
      setSelectedDesign(null);
      setShowGeneratedDesigns(true);
    };  
  
    const handleGenerateDesign = async (description) => {
        try {
          setLastDescription(description);
          setError(null);
          setIsLoading(true); // Set isLoading to true before making the API call
          setShowPrompt(false);
          setShowGeneratedDesigns(true);
          const images = await generateImage(description);
          setGeneratedDesigns(images);
      
           //Loop through images and upload each image to S3
          // for (let i = 0; i < images.length; i++) {
          //     await uploadFileFromUrl(images[i]);
          // }
        } catch (error) {
          setError(error.message);
          console.error(error);
        } finally {
          setIsLoading(false); // Set isLoading to false after the API call is complete
        }
    };

    const handleDesignSelect = (design) => {

        setShowGeneratedDesigns(false);
        setSelectedDesign(design);
    };

    const handleAddToCartAndContinue = (product) => {
      //const storedCart = JSON.parse(localStorage.getItem('cart'));
      //storedCart.addToCart(selectedDesign)
      setSelectedDesign(product)
      addToCart(product);
      handleDesignDeselect();
    };
    
    const handleAddToCartAndCheckout = (product) => {
      setSelectedDesign(product)
      addToCart(product);
      navigate('/checkout');
    };
    const handleRegenerate = () => {
      if (lastDescription) {
        handleGenerateDesign(lastDescription);
      }
    };
  
    return (
        <div>
          {error && (
            <div className="text-center">
                <Alert variant="danger">
                {error}
                </Alert>
            </div>
           )}
          {isLoading ? (
            <div className="loading-spinner-container">
            <Spinner animation="border" role="status">

            </Spinner>
            </div>
          ) : (
            <>
            <Container>
              <Container fluid className="design-shirt-header mt-5">
                <h1 className="selected-design-title" id='boldFont' style={{fontSize: 20}}>
                  {selectedDesign
                    ? 'Review Selection'
                    : showGeneratedDesigns
                    ? `Select a ${selectedOption.name} Design`
                    : showPrompt
                    ? `Describe a ${selectedOption.name} Design`
                    : 'Select Product'}
                </h1>
              </Container>
              <Container className="mt-0">
                {showPrompt && (
                  <Prompt
                    selectedOption={selectedOption}
                    handleOptionDeselect={handleBackToOptionsClick}
                    handleGenerateDesign={handleGenerateDesign}
                  />
                )}
                {showGeneratedDesigns && !isLoading && (
                  <GeneratedDesigns
                    designs={generatedDesigns}
                    handleOptionDeselect={handleBackToDescriptionsClick}
                    handleDesignSelect={handleDesignSelect}
                    isLoading={isLoading}
                    handleRegenerate={handleRegenerate}
                  />
                )}
                {selectedDesign && (
                  <SelectedDesign
                    design={selectedDesign}
                    handleDesignDeselect={handleDesignDeselect}
                    handleAddToCartAndContinue={handleAddToCartAndContinue}
                    handleAddToCartAndCheckout={handleAddToCartAndCheckout}
                  />
                )}
                {!showPrompt && !showGeneratedDesigns && !selectedDesign && (
                  <Row className="justify-content-center">
                    {options.map((option) => (
                      <Col xs={6} sm={6} md={4} lg={3} className="my-3" key={option.id}>
                        <div
                          className={`option-card ${selectedOption === option ? 'selected' : ''}`}
                          style={{ backgroundImage: `url(${option.imageUrl})` }}
                          onClick={() => handleOptionSelect(option)}
                        >
                          <div className="overlay d-flex align-items-center justify-content-center">
                            <h2 style={{fontSize: 15}} className={`text-white ${selectedOption === option ? 'selected' : ''}`}>
                              {option.name}
                            </h2>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </Container>
              </Container>
            </>
          )}
        </div>
      );
  };
  
  export default DesignShirt;