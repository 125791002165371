import React from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import '../../../styles/prompt.css';

const Prompt = ({ selectedOption, handleOptionDeselect, handleGenerateDesign }) => {
  const [description, setDescription] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);

  const exampleDesigns = [
    {
      description: 'A detailed photograph of a beautiful woman, at the beach, in a bikini, wearing sunglasses',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681530970/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/dhng8mjobvin1qz07a08.png',
    },
    {
      description: 'A painting of new york city with a beautiful sunset',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681531144/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/x3lz2cbi6zpbxkdh20dz.png',
    },
    {
      description: 'A galaxy with planets and stars',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681531666/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/oszngbaxk4fnfhd8zppw.png',
    },
    {
      description: 'A vintage image of Barack Obama smiling',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681531926/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/mybfe4ylqalu6ectol26.png',
    },
    {
      description: 'An eagle origami logo',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681532062/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/kvh12bzyminhtbvnzc38.png',
    },
    {
      description: 'Madrid, Spain, line art',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681532163/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/yovrldhlyeatnfv1gzfs.png',
    },
    {
      description: 'A man on the moon, cinematic',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681532360/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/esbkc9xnmxayswlxg7ja.png'
    },
    {
      description: 'Super Mario detailed pixel art',
      imageUrl: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681532545/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/jhqkdcutdqjkniq5k8bo.png'
    }
  ];
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if (description.trim() === '') {
      setShowAlert(true);
      return;
    }
    handleGenerateDesign(description);
  };

  const handleInputChange = (event) => {
    setDescription(event.target.value);
    setShowAlert(false);
  };

  return (
    <>
      <Row className="justify-content-center mt-3">
          <Form className="prompt" onSubmit={handleSubmit}>
            {showAlert && (
              <Alert variant="danger" onClose={() => setShowAlert(false)}>
                Please enter a description
              </Alert>
            )}
            <Form.Group controlId="formBasicPrompt">
              <Form.Control type="text" placeholder="Enter description here" value={description} onChange={handleInputChange} />
            </Form.Group>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="d-flex justify-content-center mt-3">
                <Button variant="primary" className="mx-2" size="md" type="submit">
                  Generate Design
                </Button>
                <Button variant="secondary" className="mx-2" size="md" onClick={handleOptionDeselect}>
                  Back to Options
                </Button>
              </div>
            </div>
          </Form>
      </Row>
      <Row className="justify-content-center mt-3">
        <h1 id='boldFont' className="text-center my-3" style={{fontSize: 20}}>Examples</h1>
        {exampleDesigns.map((example, index) => (
          <Col xs={6} sm={6} md={4} lg={3} className="my-0" key={index}>
            <Card style={{ borderRadius: 0 , border: '0px solid black'}}>
              <Card.Img style={{ borderRadius: 0}} variant="top" src={example.imageUrl} />
              <Card.Body>
                <Card.Text style={{fontSize: 12}}>{example.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Prompt;
