import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  Spinner,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { Image, CloudinaryContext, Transformation } from 'cloudinary-react';
import styles from '../../../styles/selecteddesign.module.css'; // Add this line

const SelectedDesign = ({
  design,
  handleDesignDeselect,
  handleAddToCartAndContinue,
  handleAddToCartAndCheckout,
}) => {
  const [tshirtColor, setTshirtColor] = useState('white');
  const [tshirtSize, setTshirtSize] = useState('M');
  const [publicId, setPublicId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sizeOptions = ['S', 'M', 'L', 'XL'];
  const colorOptions = [
    { name: 'white', colorCode: '#ffffff' },
    { name: 'black', colorCode: '#000000' },
  ];

  const handleAddToCart = (andContinue) => {
    // Create an item object to be added to the cart
    const item = {
      ...design, // Copy everything from the design
      size: tshirtSize, // Add the size
      color: tshirtColor, // Add the color
    };
  
    // Add the corresponding shirt URL to the item
    if (tshirtColor === 'white') {
      item.imageUrl = design.whiteTshirtImageUrl;
    } else if (tshirtColor === 'black') {
      item.imageUrl = design.blackTshirtImageUrl;
    }
  
    if (andContinue) {
      handleAddToCartAndContinue(item);
    } else {
      handleAddToCartAndCheckout(item);
    }
  };

  useEffect(() => {
    const tshirtPublicId =
      tshirtColor === 'white'
        ? 'v1682654844/youdesignai/websiteAssets/alejokpo_a_plain_white_t-shirt_that_fills_the_entire_screen._No_f1659ea5-b30b-45c6-9b76-f5715828cde3'
        : 'v1682654891/youdesignai/websiteAssets/alejokpo_a_plain_black_t-shirt_that_fills_the_entire_screen._No_a512d057-4815-4686-8842-3363ccdbbd99';

    setPublicId(tshirtPublicId);
  }, [tshirtColor]);

  const urlSegments = design.imageUrl.split('/');
  const versionIndex = urlSegments.findIndex(segment => segment.startsWith('v'));
  const publicIdSegments = urlSegments.slice(versionIndex + 1);
  const overlayId = publicIdSegments.join(':').replace('.png', '');

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const generateImageUrl = (publicId, overlayId) => {
    return `https://res.cloudinary.com/dhygjkoey/image/upload/l_${overlayId},w_350,g_south,y_400/${publicId}.png`;
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-spinner-container">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <CloudinaryContext cloudName="dhygjkoey">
            <Row>
              <div className="d-flex justify-content-center mt-3">
                <h4 style={{ fontSize: 14, fontWeight: 'bold' }}>{design.description}</h4>
              </div>
            </Row>
            <Row className="justify-content-center">
              <Col md={4} className="my-3 text-center">
                <Card style={{ border: 0 }}>
                  <Image publicId={publicId} >
                    <Transformation
                      overlay={overlayId}
                      width="350"
                      gravity="south"
                      y="400"
                    />
                  </Image>
                  <Card.Body>
                    <div className="d-flex justify-content-center mb-2">
                      <label
                        htmlFor="tshirtSize"
                        className="me-2 mt-1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Size:
                      </label>
                      <ButtonGroup>
                      {sizeOptions.map((size) => (
                        <ToggleButton
                          key={size}
                          id={`tshirtSize-${size}`}
                          type="radio"
                          name="tshirtSize"
                          value={size}
                          checked={tshirtSize === size}
                          onChange={(e) => setTshirtSize(e.target.value)}
                          className={`${tshirtSize === size ? styles.sizeActive : styles.size_buttons}`}
                        >
                          {size}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                    </div>
                    <div className="d-flex justify-content-center">
                      <label
                        htmlFor="tshirtColor"
                        className="mt-1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Color:
                      </label>
                      <ButtonGroup>
                      {colorOptions.map((color) => (
                        <ToggleButton
                          key={color.name}
                          id={`tshirtColor-${color.name}`}
                          type="radio"
                          name="tshirtColor"
                          value={color.name}
                          checked={tshirtColor === color.name}
                          onChange={(e) => setTshirtColor(e.target.value)}
                          className={`${styles.toggleButton} ${tshirtColor === color.name ? styles.active : ''} ${styles.color_buttons} ${styles.color_btn}`}
                        >
                          <span
                            className={`${styles.colorCircle} ${color.name === 'white' ? styles.colorWhite : ''}`}
                            style={{
                              backgroundColor: color.colorCode,
                            }}
                          ></span>
                        </ToggleButton>
                      ))}
                      </ButtonGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="auto" className="d-flex justify-content-around">
                <Button
                  variant="primary"
                  className="me-2 button_background"
                  size="md"
                  onClick={() => handleAddToCart(true)}
                >
                  Add to Cart
                </Button>
                <Button
                  variant="primary"
                  className="me-2 button_background"
                  size="md"
                  onClick={() => handleAddToCart(false)}
                >
                  Checkout
                </Button>
                <Button
                  variant="secondary"
                  className="me-2 button_background"
                  size="md"
                  onClick={handleDesignDeselect}
                >
                  Back to Designs
                </Button>
              </Col>
            </Row>
          </CloudinaryContext>
          </>
        )}
      </>
    );
  };

  export default SelectedDesign;
