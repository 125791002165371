import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container className="text-center">
        <p>
          &copy; {new Date().getFullYear()} YouDesign.ai | <Link to="/about">About Us</Link>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;