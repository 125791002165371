import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../../styles/reviews.css';

const randomDesigns = [
  {
    id: 1,
    description: 'A creative splattered paint design, in a white background',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681532706/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/dss4hyp58mn2itonctgq.png'
  },
  {
    id: 2,
    description: 'A futuristic portrait of a man, dynamic, fashion magazine, particulate, rich colors, intricate, elegant, highly detailed',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681533020/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/ffqfmv38iq4ozsv8mkke.png'
  },
  {
    id: 3,
    description: 'A doodle of a beautiful mountain range in Switzerland',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681533115/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/moo5iidl4wbmnekla3bi.png'
  }
  ,
  {
    id: 4,
    description: 'A tranquil sailboat in a massive ocean',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681534106/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/eo4iwfm7oc5jfoy38egt.png'
  },
  {
    id: 5,
    description: 'Lionel messi lifting a trophy, intricate, detailed',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681533268/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/imj3ehndaidh9fvbsso3.png'
  },
  {
    id: 6,
    description: 'A black and white painting of San Francisco Golden Gate bridge from far away',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681533470/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/dew7sbfh1vr605z4w38s.png'
  },
  {
    id: 7,
    description: 'A photo of Donald Trump riding a bike',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681533651/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/ukqx2ld3tjskdch5gh0x.png'
  },
  {
    id: 8,
    description: 'An Andy Warhol style painting of a goldendoodle wearing sunglasses',
    image: 'https://res.cloudinary.com/dhygjkoey/image/upload/v1681534024/youdesignai/us-east-1:3c4fc2b4-17fc-4485-bfe2-f998ce99ad95/w3ju0r3fxnmcw6fcnwon.png'
  }
];

const RandomDesigns = () => {
  return (
    <>
      <h2 id='boldFont' style={{ fontSize: 20 }}>Customer Generated Designs</h2>
      <Row className="mt-4">
        {randomDesigns.map(design => (
          <Col key={design.id} xs={6} sm={6} md={4} lg={3}>
            <Card className="mb-0" style={{ borderRadius: 0 , border: '0px solid black'}}>
              <Card.Body>
                <Card.Img style={{ borderRadius: 0 }} src={`${design.image}`} />
                <Card.Text style={{fontSize: 12}}>{design.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RandomDesigns;
