import { Auth } from 'aws-amplify';
import axios from 'axios';

export async function uploadFileFromUrl(image) {
  const identityId = await Auth.currentCredentials().then((creds) => creds.identityId);

  const response = await axios.post('https://307bjwf3pk.execute-api.us-east-1.amazonaws.com/dev/getImageURL', {
    imageUrl: image.imageUrl,
  });
  const { imageData, contentType } = response.data;
  const key = `users/${identityId}/${Date.now()}`;

  // Call the API Gateway to trigger the Lambda function
  await axios.post('https://e1in1jq805.execute-api.us-east-1.amazonaws.com/dev/insertImage', {
    key,
    contentType,
    identityId,
    imageUrl: image.imageUrl,
    description: image.description,
    imageData,
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export async function getDesigns() {
  const identityId = await Auth.currentCredentials().then((creds) => creds.identityId);

  // Call the API Gateway to trigger the Lambda function
  const response = await axios.post('https://e1in1jq805.execute-api.us-east-1.amazonaws.com/dev/getImages', {
    identityId,
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data.designs;
}

export async function uploadToCloudinary(image) {
  const identityId = await Auth.currentCredentials().then((creds) => creds.identityId);

  const key = `users/${identityId}/${Date.now()}`;

  // Call the API Gateway to trigger the Lambda function
  await axios.post('https://e1in1jq805.execute-api.us-east-1.amazonaws.com/dev/insertImage', {
    key,
    identityId,
    imageUrl: image.imageUrl,
    description: image.description,
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
