import { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
  const [cart, setCart] = useState(storedCart);

  useEffect(() => {
    // Initialize cart with items that were already added before
    // the component was mounted
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    console.log(storedCart);
    if (storedCart) {
      setCart(storedCart);
    }
  }, [setCart]);

  const emptyCart = (storedCart) => {
    setCart([]);
  };

  const addToCart = (item, restore = false) => {
    setCart((prevCart) => {
      if (restore) {
        return [...prevCart, item];
      }

    const itemIndex = prevCart.findIndex((cartItem) => cartItem.imageUrl === item.imageUrl);
  
      if (itemIndex !== -1) {
        // If the item exists, create a new array with updated quantity
        return prevCart.map((cartItem, index) => {
          if (index === itemIndex) {
            return { ...cartItem, quantity: cartItem.quantity + 1 };
          }
          return cartItem;
        });
      } else {
        // If the item doesn't exist, add it to the cart with quantity 1
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (item) => {
    setCart((prevCart) => {
      const itemIndex = prevCart.findIndex((cartItem) => cartItem.imageUrl === item.imageUrl);

      console.log("RemoveFromCart", itemIndex)
  
      if (itemIndex !== -1) {
        const updatedCart = prevCart.map((cartItem, i) => {
          if (i === itemIndex) {
            return { ...cartItem, quantity: cartItem.quantity - 1 };
          }
          return cartItem;
        });
  
        return updatedCart.filter((cartItem) => cartItem.quantity > 0);
      }
  
      return prevCart;
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, setCart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};