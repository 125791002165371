import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CustomNavbar from './components/Navbar/CustomNavbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import DesignShirt from './pages/DesignShirt/DesignShirt';
import Checkout from './pages/Checkout/Checkout';
import About from './pages/About/About';
import SignIn from './pages/Authentication/SignIn';
import RequireAuth from './components/Authentication/RequireAuth';
import Account from './pages/Account/Account';
import { Authenticator } from '@aws-amplify/ui-react';
import './styles/app.css';
import '@aws-amplify/ui-react/styles.css';
import { CartProvider } from './components/ShoppingCart/CartContext';
import PurchaseSummary from './pages/Checkout/PurchaseSummary';
import Analytics from './components/Analytics/Analytics'; // Add this line

function App() {
  return (
    <Authenticator.Provider>
      <CartProvider>
        <BrowserRouter>
          <Analytics /> {/* Add this line */}
          <CustomNavbar />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/design-shirt" element={<DesignShirt />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/about" element={<About />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/account" element={<RequireAuth><Account /></RequireAuth>} />
              <Route path="/purchase-summary" element={<PurchaseSummary />} />
            </Routes>
          </main>
          <Footer />
        </BrowserRouter>
      </CartProvider>
    </Authenticator.Provider>
  );
}

export default App;
