import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/navbar.css'; // Make sure the path is correct
import { useAuthenticator } from '@aws-amplify/ui-react';
import { BsFillBagFill } from 'react-icons/bs';
import '../../styles/fonts.css'

const logo = 'https://res.cloudinary.com/dhygjkoey/image/upload/v1690692739/youdesignai/websiteAssets/hanger_logo2.png';

const CustomNavbar = () => {
  const { authStatus, signOut } = useAuthenticator((context) => [
    context.authStatus,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/');
  }

  const isLoggedIn = authStatus === 'authenticated';

  return (
    <Navbar bg="black" variant="dark" expand="lg" className="custom-navbar fixed-top">
      <Container fluid>
        <Navbar.Toggle id="navbar-toggle" aria-controls="basic-navbar-nav" className="order-0" style={{ border: 0, color: 'white'}}></Navbar.Toggle>
        <Navbar.Brand as={Link} to="/" className="text-md-center nav-title" style={{fontFamily: 'Westmount, Fallback, sans-serif'}}><img src={logo} alt="Logo" style={{width: '75px', height: '75px', marginRight:'-10px'}} />You Design AI</Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav" className="order-3 justify-content-between">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/design-shirt">
              Design
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            {!isLoggedIn && (
              <Nav.Link as={Link} to="/signin">
                Sign In
              </Nav.Link>
            )}
            {isLoggedIn && (
              <>
                <Nav.Link as={Link} to="/account">
                  Account
                </Nav.Link>
                <Nav.Link onClick={logOut}>
                  Sign Out
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <Nav className="ml-auto order-4">
        <Nav.Link as={Link} to="/checkout" style={{ color: 'white' }}>
              <BsFillBagFill style={{ fontSize: '20px', marginTop: '-5px' }} />
        </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
