// In your APIs folder
export async function retrieveOrders(email) {
  try {
    const response = await fetch('https://nbud6mpub3.execute-api.us-east-1.amazonaws.com/dev/getUserPayments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const ordersData = await response.json();
    return ordersData;
  } catch (error) {
    console.error('Error fetching orders data:', error);
  }
}

