import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/home.css';
import FeaturedProducts from './FeaturedProducts/FeaturedProducts';
import RandomDesigns from './RandomDesigns/RandomDesigns';
import Footer from '../../components/Footer/Footer';

const Home = () => {
  return (
    <> 
    <Container>
    <Container>
      <div className="hero d-flex align-items-center justify-content-center mt-5">
        <div className="background-image"></div>
        <Container className="text-center mb-8" style={{ marginTop: '-100px' }}>
            <h1 id='mainTitle'>AI Creativity at Your Fingertips</h1>
            <div className="mt-4">
            <Button as={Link} to="/design-shirt" className="mr-3 button_background" size="lg" variant="primary">
                Design a Shirt
            </Button>
            </div>
        </Container>
      </div>
      </Container>
      <Container className="text-center mt-3">
        <FeaturedProducts />
        <RandomDesigns />
      </Container>
      </Container>
    </>
  );
};

export default Home;