import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51MssuEJuTTTfRKnrgY05PAKiucOM25UdlFD4Jl7g5wNWBAfVtiWJB1jHsAGBjmOWAarBs8hFU8eAwbFdLNptoGVf00Jj4Le9wG');

export async function handleCheckout(cart, price, email) {
  const stripe = await stripePromise;

  const payload = {
    amount: price * 100,
    currency: 'usd',
    payment_method_types: ['card'],
    cart,
    email: email
  };


  try {
    const { data } = await axios.post('https://wel08bmhgd.execute-api.us-east-1.amazonaws.com/dev/checkout',
    payload,
    {
      headers: {
        'Content-Type': 'application/json'
      },
    });

    // Redirect to Stripe checkout page
    const result = await stripe.redirectToCheckout({
      sessionId: data.sessionId,
    });

    if (result.error) {
      // Handle any errors during checkout
      console.error(result.error.message);
    }
  } catch (error) {
    console.error('Error calling Lambda function:', error)
  }
}
