import React from 'react';
import { Container } from 'react-bootstrap';
import '../../styles/about.css';

const About = () => {
  return (
    <Container>
    <Container className="about mt-0">
      <div className="about-header">
      </div>
      <Container className="about mt-5">
        <p className="about-text">
          <span className="bold">YouDesign.ai</span> is a revolutionary platform that merges technology and creativity to offer a unique, personalized design experience. We enable users to create custom designs for various products using our cutting-edge <span className="bold">AI-generated images</span>. Our mission is to make the design process easier, more accessible, and more sustainable for everyone.
        </p>
        <p className="about-text">
          We understand that each person has unique passions and interests, which is why we created YouDesign.ai. Our platform allows you to <span className="bold">express yourself</span> through one-of-a-kind designs that are tailored to your specific preferences. With a few simple steps, you can input a description, and our AI will generate multiple design options for you to choose from, making the process seamless and enjoyable.
        </p>
        <p className="about-text">
          At YouDesign.ai, we are committed to promoting <span className="bold">sustainability</span> in our business practices. We carefully select suppliers and production houses that share our vision and maintain high-quality standards. Our products are designed to be as environmentally friendly as possible, ensuring a positive impact on both the planet and our customers.
        </p>
        <p className="about-text">
          No matter who you are, where you are, or what you're passionate about, we want to provide you with high-quality, sustainable products that help you express yourself. And if you ever need assistance or have suggestions, our dedicated team is always here to support you. At YouDesign.ai, we strive to be your lifetime partner in creating unique, <span className="bold">sustainable designs</span> that you can be proud of.
        </p>
      </Container>
      <div className="about-footer">
      </div>
    </Container>
    </Container>
  );
};

export default About;
