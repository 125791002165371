import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../../../styles/generateddesigns.css';
import { Image, CloudinaryContext, Transformation } from 'cloudinary-react';

const GeneratedDesigns = ({ designs, handleOptionDeselect, handleDesignSelect, handleRegenerate }) => {
    // The rest of the component remains the same
    let description = ""
    if (designs?.length > 0) {
      description = designs[0].description
    }
    let whiteTshirtPublicId = "v1682654844/youdesignai/websiteAssets/alejokpo_a_plain_white_t-shirt_that_fills_the_entire_screen._No_f1659ea5-b30b-45c6-9b76-f5715828cde3"
    let blackTshirtPublicId = "v1682654891/youdesignai/websiteAssets/alejokpo_a_plain_black_t-shirt_that_fills_the_entire_screen._No_a512d057-4815-4686-8842-3363ccdbbd99"

    const getOverlayId = (imageUrl) => {
      const urlSegments = imageUrl.split('/');
      const versionIndex = urlSegments.findIndex(segment => segment.startsWith('v'));
      const publicIdSegments = urlSegments.slice(versionIndex + 1);
      const overlayId = publicIdSegments.join(':').replace('.png', '');

      return overlayId
    };

    const generateImageUrl = (publicId, overlayId) => {
      return `https://res.cloudinary.com/dhygjkoey/image/upload/l_${overlayId},w_350,g_south,y_400/${publicId}.png`;
    };
  
    const handleDesignSelectWithTransformation = (design) => {
      const overlayId = getOverlayId(design.imageUrl);
      const whiteTshirtImageUrl = generateImageUrl(whiteTshirtPublicId, overlayId);
      const blackTshirtImageUrl = generateImageUrl(blackTshirtPublicId, overlayId);
  
      // Call handleDesignSelect with the transformed URLs instead of the design object
      handleDesignSelect({
        ...design,
        whiteTshirtImageUrl,
        blackTshirtImageUrl,
      });
    };
    return (
        <>
        <Row className="justify-content-center mt-3">
          <div className='col text-center'>
                <h4 style={{fontSize: 14, fontWeight: 'bold'}}>{description}</h4>
          </div>
        </Row>
        <Row>
            {designs.map((design) => (
            <Col xs={6} sm={6} md={4} lg={4} className="my-3" key={design.id}>
                <Card style={{ borderRadius: 0, border: 0 }} className="generated-designs-card" onClick={() => handleDesignSelectWithTransformation(design)}>
                  <img src={design.imageUrl} />
                </Card>
                <CloudinaryContext cloudName="dhygjkoey">
                    <Image publicId={whiteTshirtPublicId} style={{ display: 'none' }}>
                      <Transformation
                        overlay={getOverlayId(design.imageUrl)}
                        width="350"
                        gravity="south"
                        y="400" />
                    </Image>
                    <Image publicId={blackTshirtPublicId} style={{ display: 'none' }}>
                      <Transformation
                        overlay={getOverlayId(design.imageUrl)}
                        width="350"
                        gravity="south"
                        y="400" />
                    </Image>
                  </CloudinaryContext>
              </Col>
            ))}
        </Row>
        <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="d-flex justify-content-center mt-3">
                <Button variant="primary" className="mx-2 button_background" size="md" onClick={handleOptionDeselect}>
                  Try New Description
                </Button>
                <Button variant="secondary" className="mx-2 button_background" size="md" onClick={handleRegenerate}>
                  Regenerate
                </Button>
              </div>
            </div>
        </>
    );
  };
  

export default GeneratedDesigns;
