import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { fetchSessionData } from '../../apis/get_stripe_session';
import { useNavigate } from 'react-router-dom';
import '../../styles/accountpage.css';
import { useCart } from '../../components/ShoppingCart/CartContext';

const PurchaseSummary = () => {
const { clearCart } = useCart();
  const [sessionData, setSessionData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('cart');
    clearCart();
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      fetchSession(sessionId);
    }
  }, []);

  async function fetchSession(sessionId) {
    const data = await fetchSessionData(sessionId);
    const imageUrls = JSON.parse(data.payment_intent.metadata.image_urls);
    const newData = { ...data, imageUrls };
    console.log('Session data:', newData);
    setSessionData(newData);
  }
  

  function displayOrderDetails() {
    if (!sessionData) return <p>Loading...</p>;

    const shipping = sessionData.payment_intent.shipping;
    
    if (!shipping) return <p>Shipping data not available.</p>;  

    return (
        <Container className="mt-4">
      <Container className="account-page mt-5 pt-3">
        <Row>
          <Col>
            <h1>Thank you for your purchase!</h1>
            <h2>Order Details:</h2>
            <p>Customer: {sessionData.customer_details.email}</p> {/* Access email from customer_details */}
            <p>Shipping Address:</p>
            <pre>
              {shipping.address.line1}
              {'\n'}
              {shipping.address.city}, {shipping.address.state} {shipping.address.postal_code}
              {'\n'}
              {shipping.address.country}
            </pre>
            <h2>Items Purchased:</h2>
            <Card className="mb-3">
            {sessionData.line_items.data.map((item, index) => (
                <Card.Body key={index} className="py-2">
                <Row>
                    <Col md="auto" className="d-flex justify-content-center align-items-center">
                    <Card.Img
                        src={sessionData.imageUrls[index] || 'https://via.placeholder.com/100'}
                        alt="Design"
                        style={{ width: '100%', maxWidth: '100px', height: 'auto' }}
                    />
                    </Col>
                    <Col className="d-flex align-items-center">
                    <div>
                        {item.description} - {item.quantity} x ${(30 / 100).toFixed(2)}
                    </div>
                    </Col>
                </Row>
                </Card.Body>
            ))}
            </Card>
            <p>Total Paid: ${(sessionData.payment_intent.amount / 100).toFixed(2)}</p>
            <Button onClick={() => navigate('/design-shirt')} variant="primary">
              Continue Designing
            </Button>
          </Col>
        </Row>
      </Container>
      </Container>
    );
  }

  return <>{displayOrderDetails()}</>;
}

export default PurchaseSummary;
