import { Configuration, OpenAIApi } from "openai";
import axios from 'axios';
import { Auth } from 'aws-amplify';
//import midjourney from "midjourney-client"
// Create a new OpenAI configuration
const config = new Configuration({
    apiKey: "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"
});

// Create a new OpenAI API client
const openai = new OpenAIApi(config);

/*
Function that generates a prompt based on a prompt
    input: string prompt - prompt you want AI to generate
    output: string prompt - prompt generated by AI
*/
export async function generatePrompt(textInput) {
    try{
        const response = await openai.createCompletion({
            prompt: textInput,
            model: "text-davinci-003",
            max_tokens: 3000,
        });

        return response.data.choices[0].text;
    } catch(error){
        console.log(error)
    }
}

/*
Function that generates an AI image based on a prompt 
    input: string prompt - prompt you want AI to generate
    output: Jimp image object
*/

export async function generateImage(textInput) {
    const currentUser = await Auth.currentUserInfo();
    const userId = currentUser ? currentUser.id : "noAuthUser";

    try {
      const response = await axios.post('https://2kjosthn0c.execute-api.us-east-1.amazonaws.com/dev/generateImage', { textInput });
  
      const imageUrls = await Promise.all(response.data.map(async (imageData, index) => {
        const imageUrl = `data:image/png;base64,${imageData.base64}`;
  
        // Call the Lambda function to upload the image to Cloudinary
        const uploadResponse = await axios.post('https://e1in1jq805.execute-api.us-east-1.amazonaws.com/dev/insertImage', {
          imageUrl,
          description: textInput,
          userId,
        });
  
        const cloudinaryUrl = uploadResponse.data.imageUrl;
  
        return { id: index + 1, description: textInput, imageUrl: cloudinaryUrl, quantity: 1 };
      }));
  
      return imageUrls;
    } catch (error) {
      throw new Error("Failed to generate images. Please try again.");
    }
  }
  
  
