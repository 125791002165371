import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/signin.css';
import { Container } from 'react-bootstrap';


const SignIn = () => {
  return (
    <Container className="mt-5">
      <div className="mt-5">
      <Authenticator>
        <SignInContent />
      </Authenticator>
      </div>
    </Container>
  );
};

const SignInContent = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();


  const from =
    (location.state && location.state.from && location.state.from.pathname) ||
    '/account';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <View className="auth-wrapper"></View>
  );
};

export default SignIn;
