import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Table, Alert } from 'react-bootstrap';
import { useCart } from '../../components/ShoppingCart/CartContext';
import { useNavigate } from 'react-router-dom';
import { handleCheckout } from '../../apis/create_checkout_session';
import { Auth } from 'aws-amplify';
import { insertImageUrl } from '../../apis/dynamo_db';

const Checkout = () => {
  const { cart, addToCart, removeFromCart, setCart, clearCart } = useCart();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const price = 25;

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
  localStorage.setItem('cart', JSON.stringify(cart));
}, [cart]);

  const increaseQuantity = (item) => {
    addToCart(item, false);
  };

  const decreaseQuantity = (item) => {
    removeFromCart(item);
  };

  const callCheckoutLambda = async (cart, price) => {
    localStorage.setItem('cart', JSON.stringify(cart));
  
    try {
      const updatedCart = await Promise.all(
        cart.map(async (item) => {
          const id = await insertImageUrl(item.imageUrl);
          console.log("ID: ", id)
          return { ...item, imageUrl: id };
        })
      );

      console.log("UPDATED CART: ", updatedCart)
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart)
  
      // Proceed with the Stripe checkout session using the updated cart
      handleCheckout(updatedCart, price, email);
    } catch (error) {
      console.error('Error inserting image URL:', error);
    }
  };

  const getUserInfo = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setEmail(currentUser.attributes.email);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const cartEmpty = cart.length === 0;

  return (
    <>
      <Container fluid className="design-shirt-header mt-5">
        <h1 id="boldFont" style={{fontSize: 20}}>Shopping Cart</h1>
      </Container>
      <Container className="my-3">
        {showAlert && (
          <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
            Item added to cart.
          </Alert>
        )}
        {cartEmpty ? (
          <>
          <Container className="text-center">
            <div>Your cart is currently empty.</div>
            <Button className='mt-3 button_background' size="md" onClick={() => navigate('/design-shirt')}>
              Continue designing
            </Button>
          </Container>  
          </>
        ) : (
          <>
            <Table>
              <thead>
                <tr style={{fontSize: 14}}>
                  <th style={{fontWeight: 'normal'}} className="">Product</th>
                  <th style={{fontWeight: 'normal'}} className="">Size</th>
                  <th style={{fontWeight: 'normal'}} className="">Price</th>
                  <th style={{fontWeight: 'normal'}} className="">Quantity</th>
                  <th style={{fontWeight: 'normal'}} className=""> Total</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Row>
                        <Col md={'auto'} className="d-flex justify-content-center align-items-center">
                          <Card.Img
                            src={item.imageUrl}
                            alt="Design"
                            style={{ width: '100%', maxWidth: '100px', height: 'auto' }}
                          />
                        </Col>
                      </Row>
                    </td>
                    <td  className="">
                      <div style={{fontSize: 12}}>
                        {item.size}
                      </div>
                    </td>
                    <td  className="">
                      <div style={{fontSize: 12}}>
                        ${price}
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <Button style={{fontSize: 10}} variant="outline-secondary" className="button_background" onClick={() => decreaseQuantity(item, index)} size="sm">-</Button>
                        <span style={{fontSize: 12}} className="mx-2">{item.quantity}</span>
                        <Button style={{fontSize: 10}} variant="outline-secondary" className="button_background" onClick={() => increaseQuantity(item)} size="sm">+</Button>
                      </div>
                    </td>
                    <td className="">
                      <div style={{fontSize: 12}}>
                        ${item.quantity * price}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row className="justify-content-end">
              <Col xs="auto">
                <h4 style={{fontSize: 14, fontWeight: 'bold'}}>Subtotal: ${cart.reduce((acc, item) => acc + item.quantity * price, 0)}</h4>
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <Row>
                <Col>
                  <Button size="md" className="button_background" onClick={() => callCheckoutLambda(cart, price)}>
                    Checkout
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
    </>
  );  
};

export default Checkout;
