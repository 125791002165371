// In your APIs folder
export async function fetchSessionData(sessionId) {
    try {
      const response = await fetch('https://nbud6mpub3.execute-api.us-east-1.amazonaws.com/dev/session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId }),
      });
      const sessionData = await response.json();
      return sessionData;
    } catch (error) {
      console.error('Error fetching session data:', error);
    }
  }
